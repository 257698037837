class removeFields {
    // This executes when the function is instantiated.
    constructor() {
        this.iterateLinks()
    }

    iterateLinks() {
        // Use event delegation to ensure any fields added after the page loads are captured.
        document.addEventListener('click', e => {
            if (e.target && e.target.className === 'remove_fields') {
                this.handleClick(e.target, e)
            }
        })
    }

    handleClick(link, e) {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!link || !e) return
        // Prevent the browser from following the URL.
        e.preventDefault()
        // Find the parent wrapper for the set of nested fields.
        let fieldParent = link.closest('.person-nested-fields')
        // If there is a parent wrapper, find the hidden delete field.
        let deleteField = fieldParent
            ? fieldParent.querySelector('input[type="hidden"]')
            : null
        // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
        if (deleteField) {
            deleteField.value = 1
            fieldParent.style.display = 'none'
        }
        // If there is a parent wrapper, find the name field.
        let nameField = fieldParent
            ? fieldParent.querySelectorAll("[id$='name']")
            : null
        // If there is a name field, update the required value to true
        if (nameField) {
            nameField.forEach(function(item) {
                item.required = false;
            });
        }
        // If there is a parent wrapper, find the position field.
        let positionField = fieldParent
            ? fieldParent.querySelectorAll("[id$='position']")
            : null
        // If there is a position field, update the required value to true
        if (positionField) {
            positionField.forEach(function(item) {
                item.required = false;
            });
        }
        // If there is a parent wrapper, find the url field.
        let urlField = fieldParent
            ? fieldParent.querySelectorAll("[id$='url']")
            : null
        // If there is a name field, update the required value to true
        if (urlField) {
            urlField.forEach(function(item) {
                item.required = false;
            });
        }
    }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new removeFields())